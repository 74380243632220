/*  custom-date-range.css for react-date-range calendar */
.rdrCalendarWrapper {
  font-size: 0.75rem; 
}

.rdrMonthAndYearPickers select {
  font-size: 0.75rem;
}

.rdrDayNumber span {
  font-size: 0.75rem;
}

.rdrMonthName {
  text-align: center;
  font-weight: 600;
  color: #101d41;
  padding: 0.833em;
  font-size: 1rem;
}

.rdrDateDisplay {
  margin: 0;
  padding-left: 8px;
}

.rdrDateDisplayItem {
  box-shadow: none;
  border: 1px solid transparent;
}

.rdrDateInput > input {
  text-align: left;
  color: #101d41;
  font-size: 1rem;
}

.rdrMonthAndYearWrapper{
position: relative;
}

.rdrNextPrevButton {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 1.833em;
  padding: 0;
  border: 0;
  border-radius: 5px;
  background: #EFF2F7;
  position: absolute;
  top: 11px;
}

.rdrPprevButton{
  left: 0;
}

.rdrNextButton  {
  right: 0;
}

.rdrPprevButton i, 
.rdrNextButton i {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.rdrPprevButton i {
  margin: 0 0 0 2px;
  transform: rotate(135deg);
}

.rdrNextButton i {
  margin: 0 0 0 -2px;
  transform: rotate(-45deg);
}

.rdrMonthPicker, 
.rdrYearPicker {
  display: none !important;
}

.rdrMonthAndYearWrapper {
  align-items: center;
  height: 0px;
  padding-top: 0px;
}
/* do not remove, month and year pickers temporaly hidden */
/* .rdrMonthPicker, 
.rdrYearPicker {
  background: #e9eef4;
  border-radius: 1.25rem;
  position: relative;
}

.rdrMonthPicker:hover, 
.rdrYearPicker:hover {
  background: #bfc8df;
} */
 /* do not remove, month and year pickers temporaly hidden */

.rdrMonthAndYearPickers select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background: transparent;
  padding: 10px 30px 10px 10px;
  border-radius: 4px;
  outline: 0;
  background-position: right 8px center;
  cursor: pointer;
  text-align: center;
  font-size: 1rem;
}

/* do not remove, month and year pickers temporaly hidden */
/* .rdrMonthPicker::after, 
.rdrYearPicker::after {
  content: "";
  position: absolute;
  top: 1rem;
  transform: translateY(-50%) rotate(45deg);
  border: solid #3e484f;
  border-width: 0 3px 3px 0;
  padding: 3px;
  display: inline-block;
  pointer-events: none;
}

.rdrMonthPicker::after {
  right: 1.25rem;
}

.rdrYearPicker::after {
  right: 0.75rem;
} */
/* do not remove, month and year pickers temporaly hidden */

.rdrMonthAndYearPickers select option {
  color: inherit;
  background-color: white;
}

.rdrInRange,
.rdrDayWeekend span ,
.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber,
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber,
.rdrStartEdge, .rdrEndEdge , .rdrDayEndOfWeek , .rdrDayStartOfMonth ,
.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrEndEdge,
.rdrDayStartOfWeek .rdrInRange, 
.rdrDayStartOfWeek .rdrEndEdge,
.rdrDayHovered,
.rdrDayStartPreview, .rdrDayEndPreview, .rdrStartEdge,  .rdrEndEdge,
.rdrDayInPreview {
  border-top-left-radius: .5rem !important;
  border-top-right-radius: .5rem !important;
  border-bottom-left-radius: .5rem !important;
  border-bottom-right-radius: .5rem !important;
  left: 0px !important;
  right: 0px !important;
  top: 0px !important;
  bottom: 0px !important;
}

.rdrInRange {
  color: #F1F5FF !important;
}

.rdrDayWeekend span  {
  color:#394952 !important;
} 

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: #101D41 !important;
}

.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span{
  color: #FFFFFF !important;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber,
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber {
  background-color: #d5eaf5;
}

.rdrDay{
  background: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 0;
  padding: 0;
  height: 34px;
  width: 32px;
  text-align: center;
}


.rdrDayStartPreview, .rdrDayEndPreview, .rdrStartEdge,  .rdrEndEdge{
  background: #108BE2 !important;
} 
.rdrDayHovered > .rdrDayNumber span{
  color:#fff !important;
} 

.rdrDayStartOfWeek span,
.rdrDayEndOfWeek span{
  border-top-left-radius: .5rem !important;
  border-top-right-radius: .5rem !important;
  border-bottom-left-radius: .5rem !important;
  border-bottom-right-radius: .5rem !important;
  border: none !important;
  left: 0px !important;
  right: 0px !important;
  top: 0px !important;
  bottom: 0px !important;
}

.rdrDayInPreview{
  border-top-width: 0px;
  border-bottom-width: 0px;
  background-color: #F1F5FF;
  left: 0px !important;
  right: 0px !important;
  top: 0px !important;
  bottom: 0px !important;
}

.rdrDayNumber span{
color: #108BE2;
z-index: 10000000;
}

.rdrDay {
  margin: 1px;
}

.rdrDayNumber span {
  font-size: 14px;
}

.rdrMonth {
  width: 290px;
  padding: 0px;
}

.rdrDays {
  padding: 0 16px 0 16px;
}

.rdrDay {
  margin: 2px;
}

.rdrWeekDays {
  width: 290px;
  padding: 0 16px !important;
}

.rdrDateInput > input {
  text-align: left;
  color: #7d888d;
  font-size: 1rem;
  height: 2rem;
  border: 1px solid #E9EEF4;
  border-radius: 0.5rem;
  width: 90%;
  padding-left: 10px;
}

.rdrDateInput > input:focus { 
  border-color: silver;
  color: #101d41;
} 

.rdrDayEndOfMonth .rdrInRange,
.rdrDayEndOfMonth .rdrStartEdge,
.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrStartEdge {
  border-top-right-radius: .5rem !important;
  border-bottom-right-radius: .5rem !important;
  border: none !important;
  left: 0px !important;
  right: 0px !important;
  top: 0px !important;
  bottom: 0px !important;
}
.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrStartEdge,
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrStartEdge {
  border-top-left-radius: .5rem !important;
  border-top-right-radius: .5rem !important;
  border-bottom-left-radius: .5rem !important;
  border-bottom-right-radius: .5rem !important;
  left: 0px !important;
  right: 0px !important;
}

.rdrDateDisplayWrapper {
  background-color: #fff;
}

