.fade-in {
  animation-name: FadeIn;
  animation-duration: 0.7s;
  transition-timing-function: linear;
}

.light2 {
  animation: light2 3.8s linear infinite;
}

.dark1 {
  animation: dark1 3.2s linear infinite;
}

@keyframes light2 {
  0% {
    transform: translate(1%);
    opacity: .01;
  }


  50% {
    transform: translate(5%);
    opacity: 1;
  }


  75% {
    transform: translate(40%);
    opacity: 1;
  }


  100% {
    transform: translate(55%);
    opacity: 0.1;
  }
}

@keyframes dark1 {
  0% {
    opacity: 0;
    transform: translate(1%);
  }

  50% {
    opacity: 1;
    transform: translate(5%);
  }

  100% {
    opacity: .1;
    transform: translate(55%);
  }
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}